export const getHomeData = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/home.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error when fetching home data!");
    }

    const data = await response.json();

    let products = [];
    for (const key in data) {
      products.push({
        id: key,
        name: data[key].name,
        description: data[key].description,
        img_desktop: data[key].img_desktop,
        img_mobile: data[key].img_mobile,
      });
    }

    return products;
  } catch (error) {
    console.error(error);
  }
};

export const getCarModel = async (id) => {
  const url = `${process.env.REACT_APP_API}/items/${id}.json`;
  console.log("Fetching car model from:", url);
  
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response status:", response.status);

    // Instead of directly parsing, get the raw text to inspect the content.
    const responseText = await response.text();
    console.log("Response text:", responseText);

    // Check if responseText starts with '<' which indicates HTML.
    if (responseText.trim().startsWith("<")) {
      throw new Error("Expected JSON but got HTML. Check your endpoint and Firebase configuration.");
    }
    
    const data = JSON.parse(responseText);
    console.log("Parsed data:", data);
    return data;
  } catch (error) {
    console.error("Error fetching car model data:", error);
    throw error;
  }
};


export const getSolarModel = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/solar/${id}.json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error when fetching solar model data!");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};
